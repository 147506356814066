import React, { useEffect, useState } from 'react'
import numeral from 'numeral'

import './Property.scss'
import './PropertyOverview.scss'
import PropertyLayout from '../../components/Layout/PropertyLayout'
import PropertyBaseMenu from '../../components/PropertyBaseMenu/PropertyBaseMenu'
import PropertySlider from '../../components/PropertySlider/PropertySlider'

import Alp from '../../assets/images/brands/alp.png'
import Alveo from '../../assets/images/brands/Alveo.png'
import Avida from '../../assets/images/brands/Avida.png'
import Amaia from '../../assets/images/brands/Amaia.png'
import Bellavita from '../../assets/images/brands/BellaVita.png'
import Fold from '../../assets/images/fold.png'
import Mesa from '../../assets/svg/mesa.svg'
import { getCorrectLogoBrand } from '../../utils/getCorrectLogoBrand'
import { useDevice } from '../../utils/checkScreen'

const PropertyOverview = (overviewData: any): JSX.Element => {
  const [device, setDevice] = useState({} as any)
  const { pageContext } = overviewData
  const { property } = pageContext
  const minPrice = numeral(property.details.priceRange.lowest).format('0.0a').replace('.0', '')
  const maxPrice = numeral(property.details.priceRange.highest).format('0.0a').replace('.0', '')
  const OverviewSliderContent = property.overview ? [...property.overview.images, ...property.overview.videos] : []

  const { brand } = property.details

  const logoBrand = getCorrectLogoBrand(brand)

  const processHtml = (html: any): string => {
    return html ? html.replace(/(<([^>]+)>)/gi, '') : ''
  }

  useEffect(() => {
    setDevice(useDevice())
  }, [])

  const isPng = () => {
    const pattern = /(png|PNG)/
    return pattern.test(property.details.imageLogo)
  }

  const isAmaiaOrAvida = () => {
    return pageContext.brand == 'avida' || pageContext.brand == 'amaia'
  }

  return (
    <PropertyLayout active={1} pageData={overviewData}>
      <PropertyBaseMenu pageData={overviewData} />
      <div className="property-page-content">
        <div className="poperty-overview-page">
          <div
            className="property-overview-banner"
            id="project-overview-banner"
            style={{
              background: `linear-gradient(to right, rgba(26, 25, 25, 0.656), rgba(255, 255, 255, 0)),
            url('${property.overview.images ? property.overview.images[0] : ''}') center no-repeat`,
              backgroundSize: 'cover'
            }}
          >
            <div className="content-wrapper">
              {pageContext.brand !== 'avida' && pageContext.brand !== 'amaia' ? (
                <div className="brand-logo">
                  <img src={logoBrand} alt="brand-logo" />
                </div>
              ) : null}

              <div className="property-logo fade-in">
                <img
                  src={property.details.imageLogo}
                  className={isAmaiaOrAvida() ? (isPng() ? 'white-text' : '') : ''}
                  alt="property logo"
                />
              </div>

              <div className={`page-fold web-view-page-fold ${pageContext.brand === 'alp' ? 'no-price' : ''}`}>
                <img src={Mesa} alt="page fold" className="page-shape" />
                <img src={Fold} alt="edge fold" className="edge-fold" />

                <div className="page-fold-content">
                  <span>
                    {property.details.propertyType} <small>--------</small>
                  </span>
                  {device?.isDesktop ? (
                    <h1
                      dangerouslySetInnerHTML={{
                        __html: processHtml(property.overview.title)
                      }}
                    />
                  ) : null}
                </div>

                <div className="property-price">
                  <span>
                    <small>Starting at</small>
                    <br />₱{minPrice}-{maxPrice}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="property-overview-content">
          <div className="mobile-property-context">
            {pageContext.brand !== 'alp' && (
              <div className="property-price">
                <span>
                  <small>Starting at:</small> ₱{minPrice}-{maxPrice}
                </span>
              </div>
            )}
            <div className="property-logo-mobile">
              <img src={logoBrand} alt="brand-logo" className="x-brand-logo" />
              <img src={property.details.imageLogo} className="mobile-brand-logo" alt="property-brand-logo" />
            </div>

            {device?.isMobile ? (
              <div className="type-title">
                <span className="property-type">
                  {property.details.propertyType} <small>--------</small>
                </span>
                <h1
                  dangerouslySetInnerHTML={{
                    __html: processHtml(property.overview.title)
                  }}
                />
              </div>
            ) : null}
          </div>
          <div className="property-text">
            <p
              dangerouslySetInnerHTML={{
                __html: processHtml(property.overview.details)
              }}
            />
          </div>

          {OverviewSliderContent.length !== 0 ? (
            <PropertySlider targetBanner="project-overview-banner" images={OverviewSliderContent} />
          ) : null}
        </div>
      </div>
    </PropertyLayout>
  )
}

export default PropertyOverview
